<template>
  <div class="businessClearingForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :footerShow="false"
      @update="update"
    >
      <col2-detail>
        <col2-block title="订单信息">
          <col2-item label="所属服务" :text="detailItem.subName" />
          <col2-item label="下单途径" :text="sourceTypeMap[detailItem.sourceType]" />
          <col2-item label="订购客户" :text="detailItem.userName" />
          <col2-item label="客户地址" :text="detailItem.userAddress" />
          <col2-item label="联系方式" :text="detailItem.userPhone" />
          <col2-item label="下单时间" :text="detailItem.referTime" />
          <col2-item label="收货人" :text="detailItem.userName" />
          <col2-item label="收货地址" :text="detailItem.userAddress" />
          <col2-item label="收款单位" :text="detailItem.legalUnit" />
        </col2-block>
        <col2-block title="订单内容">
          <div class="table-wrapper">
            <table-panel
              :headers="orderHeaders"
              :tableData="orderData"
              :hasOperateColumn="false">
            </table-panel>
          </div>
        </col2-block>
        <col2-block title="支付详情">
          <col2-item :span="24" label="商家名称" :text="detailItem.busName" />
          <col2-item :span="24" label="商家账号" :text="detailItem.subUser" />
          <div class="table-wrapper">
            <table-panel
              :headers="paymentHeaders"
              :tableData="paymentData"
              :hasOperateColumn="false">
            </table-panel>
          </div>
          <div class="table-wrapper" style="margin-top:15px;">注：实付金额=订单总额-红包金额/抵价券金额；订单总额包含物流金额</div>
        </col2-block>
        <col2-block title="处理信息">
          <col2-item label="处理组织" :text="detailItem.dealOrgname" />
          <col2-item label="处理人员" :text="detailItem.dealUsername" />
          <col2-item label="处理完成时间" :text="detailItem.dealTime" />
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { queryURL } from './api'
import { sourceTypeMap } from './map'
import { Col2Block, Col2Item, Col2Detail, TablePanel } from 'components/bussiness'

export default {
  components: {
    Col2Block,
    Col2Item,
    Col2Detail,
    TablePanel
  },
  data () {
    return {
      submitConfig: {
        queryUrl: queryURL,
        queryMethod: 'post'
      },
      sourceTypeMap: sourceTypeMap,
      detailItem: {},
      orderData: [{
        itemName: '无',
        itemUnit: '无',
        itemNum: '无',
        itemPrice: '无',
        productPrice: '无'
      }],
      orderHeaders: [
        {
          prop: 'itemName',
          label: '商品名称'
        },
        {
          prop: 'itemUnit',
          label: '商品单位'
        },
        {
          prop: 'itemNum',
          label: '购买数量'
        },
        {
          prop: 'itemPrice',
          label: '单价'
        },
        {
          prop: 'productPrice',
          label: '金额（单价*数量）'
        }
      ],
      paymentData: [],
      paymentHeaders: [
        {
          prop: 'disprice',
          label: '优惠金额'
        },
        {
          prop: 'totalMoney',
          label: '订单总额'
        },
        {
          prop: 'expensesMoney',
          label: '物流金额'
        },
        {
          prop: 'redMoney',
          label: '红包金额'
        },
        {
          prop: 'paperMoney',
          label: '抵价券金额'
        },
        {
          prop: 'realMoney',
          label: '实付金额'
        },
        {
          prop: 'payType',
          label: '支付方式'
        },
        {
          prop: 'handleCharge',
          label: '收款手续费（实付金额*0.6%）'
        }
      ]
    }
  },
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.$refs.formPanel.getData({ id })
    }
    this.$setBreadcrumb('结算详情')
  },
  methods: {
    update (data) {
      data.sourceType = parseInt(data.sourceType)
      this.detailItem = data
      // 处理订单内容
      this.orderData = data.detail || this.orderData
      // 处理支付详情
      let paymentData = {
        disprice: data.disprice,
        totalMoney: data.totalMoney,
        expensesMoney: data.expensesMoney,
        redMoney: data.redMoney,
        paperMoney: data.paperMoney,
        realMoney: data.realMoney,
        payType: data.payType,
        handleCharge: this.accMul(data.realMoney, 0.006)
      }
      this.paymentData = [paymentData]
    },

    /**
     ** 乘法函数，用来得到精确的乘法结果
     ** 说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
     ** 调用：accMul(arg1,arg2)
     ** 返回值：arg1乘以 arg2的精确结果
     **/
    accMul (arg1, arg2) {
      let m = 0
      let s1 = arg1.toString()
      let s2 = arg2.toString()
      try {
        m += s1.split('.')[1].length
      } catch (e) {}
      try {
        m += s2.split('.')[1].length
      } catch (e) {}
      return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
    }
  }
}
</script>
<style lang="scss" scoped>
.businessClearingForm-wrapper {
  .table-wrapper {
    width: 100%;
    margin-left: 85px;
  }
}
</style>
