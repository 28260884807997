var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "businessClearingForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { footerShow: false },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "订单信息" } },
                [
                  _c("col2-item", {
                    attrs: { label: "所属服务", text: _vm.detailItem.subName },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "下单途径",
                      text: _vm.sourceTypeMap[_vm.detailItem.sourceType],
                    },
                  }),
                  _c("col2-item", {
                    attrs: { label: "订购客户", text: _vm.detailItem.userName },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "客户地址",
                      text: _vm.detailItem.userAddress,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "联系方式",
                      text: _vm.detailItem.userPhone,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "下单时间",
                      text: _vm.detailItem.referTime,
                    },
                  }),
                  _c("col2-item", {
                    attrs: { label: "收货人", text: _vm.detailItem.userName },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "收货地址",
                      text: _vm.detailItem.userAddress,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "收款单位",
                      text: _vm.detailItem.legalUnit,
                    },
                  }),
                ],
                1
              ),
              _c("col2-block", { attrs: { title: "订单内容" } }, [
                _c(
                  "div",
                  { staticClass: "table-wrapper" },
                  [
                    _c("table-panel", {
                      attrs: {
                        headers: _vm.orderHeaders,
                        tableData: _vm.orderData,
                        hasOperateColumn: false,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "col2-block",
                { attrs: { title: "支付详情" } },
                [
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "商家名称",
                      text: _vm.detailItem.busName,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "商家账号",
                      text: _vm.detailItem.subUser,
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "table-wrapper" },
                    [
                      _c("table-panel", {
                        attrs: {
                          headers: _vm.paymentHeaders,
                          tableData: _vm.paymentData,
                          hasOperateColumn: false,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "table-wrapper",
                      staticStyle: { "margin-top": "15px" },
                    },
                    [
                      _vm._v(
                        "注：实付金额=订单总额-红包金额/抵价券金额；订单总额包含物流金额"
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "处理信息" } },
                [
                  _c("col2-item", {
                    attrs: {
                      label: "处理组织",
                      text: _vm.detailItem.dealOrgname,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "处理人员",
                      text: _vm.detailItem.dealUsername,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "处理完成时间",
                      text: _vm.detailItem.dealTime,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }