import { mapHelper } from 'common/utils'

// 来源
const sourceType = [
  {
    text: '业主端app',
    value: 0
  },
  {
    text: '管理后台',
    value: 1
  },
  {
    text: '园区管理app',
    value: 2
  }
]

const {
  map: sourceTypeMap
} = mapHelper.setMap(sourceType)

export {
  sourceTypeMap
}
